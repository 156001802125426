
import adminUserServices from '@/services/adminUserServices';

const adminUserStoreModule = {
    namespaced: true,
    state: {
        adminUserInfo: {},
        adminAdsList: [],

    },
    mutations: {
        setAdminUserInfo(state, userInfo) {
            state.adminUserInfo = userInfo;
          },
          setUpdateAdStatus(state, adUpdatedInfo) {
            state.adminAdsList = state.adminAdsList.map((ad) => {
                if(ad._id == adUpdatedInfo.adId){
                    ad.statusApproval = adUpdatedInfo.status;
                }
                return ad
            })
          },
          setAdminAdsList(state, adminAdsList) {
              state.adminAdsList = adminAdsList;
          }
    },
    actions: {
        async getAdminAdsList({ state },filter) {
            try {
              const adminId = state.adminUserInfo?.userId || null;
              const result = await adminUserServices.getAdminAdsList(adminId,filter);
              if(result?.status_code == 200){
                return result?.data || [];
              }
              return [];
            } catch (error) {
              console.log(error);
              return error;
            }
          },
        async updateAdStatus({ commit }, adUpdatedInfo) {
            try {
                const adUpdatedInfoKeyCheck = Object.keys(adUpdatedInfo);
                if(adUpdatedInfoKeyCheck.length === 0){
                    return {
                        status: false,
                        messages:'Required fields are missing.'
                    };
                }
                const result = await adminUserServices.updateAdStatus(adUpdatedInfo);
                if(result && result?.status_code == 201){
                   commit('setUpdateAdStatus', adUpdatedInfo, { root: false });
                }
                return result;

            } catch (error) {
                console.log(error);
                return error;
            }
        }
    },
    getters: {
        getUserAdminInfo: (state) => state.adminUserInfo,
        getAdsList: (state) => state.adminAdsList

    }
}
export default adminUserStoreModule