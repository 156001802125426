import { createApp } from 'vue'
import App from './App.vue'
import router from "./router"
import {create,NPopselect,NTreeSelect,NDatePicker,NSlider} from "naive-ui"
import axios from "./services"
import localforage from 'localforage'
import SSEPlugin from "./plugins/sse";
import store from "./store"
import './registerServiceWorker'
import { createI18n } from 'vue-i18n'
import locales from './I18N/index-I18N'
import LoadingSpinner from './utils/Loading.vue'
import ShowShorterTextView from '@/utils/ShowShorterTextView.vue'
import SweatAlert from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css';

const prod = 'https://felixleap.lm.r.appspot.com';
const test = 'https://felixleap.lm.r.appspot.com';
const local = 'http://localhost:4001';
const currentBaseUrl = process.env.NODE_ENV === 'production' ? prod : process.env.NODE_ENV === 'test' ? test : local;

console.log(currentBaseUrl)



const i18n = createI18n(locales)


const naive = create({
    components: [
        NPopselect,
        NTreeSelect,
        NDatePicker,
        NSlider
    ]
})

async function copyText(value) {
    try {
      await navigator.clipboard.writeText(value);
    } catch (err) {
      console.error('Kopyalama işlemi başarısız:', err);
    }
  }


const felixleap_app = createApp(App).
use(SSEPlugin,{baseURL: currentBaseUrl}).
use(naive).
use(router).
use(store).
use(i18n).
component('ShowShorterTextView', ShowShorterTextView)
.component('Loading', LoadingSpinner)


felixleap_app.component('LoadingSpinner', LoadingSpinner)

felixleap_app.config.globalProperties.$axios = axios();
felixleap_app.config.globalProperties.$localforage = localforage;
felixleap_app.config.globalProperties.$swal = SweatAlert;

felixleap_app.config.globalProperties.$copyText = copyText



felixleap_app.mount('#app')