<template>
    <div class="my-ads-container">
      <div class="my-ads-container__header">
        <h3 class="my-ads-container__header__title">{{ $t('adsManagement') }}</h3>
      </div>
      <div class="my-ads-container__list-ads">
          <!-- <div class="my-ads-container__list-ads__item-title">
              <h4 class="my-ads-container__list-ads__item-title__title" v-for="title in titlesAds" :key="title">
                  {{ $t(title) }}
              </h4>
          </div>
          <div class="my-ads-container__list-ads__item">
              <p class="my-ads-container__list-ads__item__text" v-for="ad in ads" :key="ad?.id">
                  {{ ad }}
              </p>
          </div> -->
  
          <table class="my-ads-container__list-ads__table" :key="isAdStatusModalVisible" >
              <thead>
                  <tr class="my-ads-container__list-ads__table__tr">
                      <th v-for="title in titlesAds" :key="title" class="my-ads-container__list-ads__table__tr__th">
                          <p>{{ $t(title?.lang_ref_key) }}</p>
                      </th>

                  </tr>
              </thead>
  
              <tbody>
                  <tr class="my-ads-container__list-ads__table__tr" v-for="(ad,index) in ads" :key="ad?._id" :class="{'even-tr': index % 2 === 0}">
                      <td v-for="(title) in titlesAds" :key="title"  class="my-ads-container__list-ads__table__tr__td">
                          <div v-if="title?.lang_ref_key === 'approval'">{{ $t(ad[title?.id]) }} / <button class="change-status-modal-btn" @click="openChangeAdStatusModal(ad)">{{ $t('change') }}</button> </div>
                          <div v-else-if="title?.lang_ref_key === 'banner'" class="my-ads-container__list-ads__table__tr__td__image">
                            <img :src="ad[title?.id]?.bucketLocation" alt="ad.image">
                          </div>
                          <div v-else-if="title?.lang_ref_key === 'createdDate'">
                            {{ getModifiedDate(ad[title?.id]) }}
                          </div>
                          <ShowShorterTextView v-else-if="typeof ad[title?.id] === 'string' && ad[title?.id].length > 30 "  :text="ad[title?.id]" />
                          <p v-else>{{ ad[title?.id] }}</p>
                      </td>
                      <td class="my-ads-container__list-ads__table__tr__td">
                          <i @click="softDeleteAd(ad?._id,ad?.statusApproval)" class="bx bx-trash "></i>
                      </td>

                  </tr>
              </tbody>
  
          </table>
      </div>
    </div>
    <ModalContainer v-if="isAdStatusModalVisible" @closeModal="closeChangeAdStatusModal">
        <AdStatusChangeModal :ad="selectedAd" @closeModal="closeChangeAdStatusModal" />
    </ModalContainer>
  </template>
  
  <script>
  import getAdsManagementListTitles from '@/assets/admin/ads/getAdsManagementListTitles'
  import AdStatusChangeModal from './AdStatusChangeModal.vue'
  import ModalContainer from '@/utils/ModalContainer.vue'
  import {mapGetters} from 'vuex'
  import moment from 'moment'
  import { throttle } from 'lodash'   
  export default {
    components:{
        AdStatusChangeModal,
        ModalContainer,
    },
      data() {
          return {
              titlesAds:getAdsManagementListTitles || [],
              selectedAd:null,
              adsListFilter:{
                statusType:[],
                pagination:1,
                lang:'en'
              },
              isAdStatusModalVisible:false,
              firstSectionVisibleFields:[
                  'title',
                  'description',
                  'image',
                  'categories',
                  'createdDate',
                  'viewsCount',
                  'statusApproval',

                  
              ]
          }
      },
      
      computed: {
        ...mapGetters({getUserAdminInfo:'adminUserModule/getUserAdminInfo',ads:'adminUserModule/getAdsList'}),
        ...mapGetters(['getSelectedLanguage'])
      },

      methods: {
        openChangeAdStatusModal(ad){
            this.selectedAd = ad;
          this.isAdStatusModalVisible = true;

        },

        softDeleteAd: throttle(function(id,status){
            try {
                const role = this.getUserAdminInfo?.role;
                this.$store.dispatch('softDeleteAd', {
                adId:id,
                status,
                role
            }).then((result) => {
                if(result?.status_code == 200){
                    this.getAds();
                }
            }).catch((error) => {
                console.log(error);
            })

            } catch (error) {
                console.log(error);
            }
     
        },1000),

        closeChangeAdStatusModal(){
            this.isAdStatusModalVisible = false;
            this.selectedAd = null;
        },
        async  setAdImageLink(image){
        if(image){
            try {
               const result = await this.$store.dispatch('getAdImageLink', image)
               console.log(result)
               if(result?.status_code == 200){
                   return result?.data?.url || null
               }
    
            } catch (error) {
                console.log(error)
                return '/img/app_logos/dark_app_logo.png'
            }
 
        }
        return '/img/app_logos/dark_app_logo.png'

    },

    async  setAdVideoLink(video){
        if(video){
            try {
               const result = await this.$store.dispatch('getAdVideoLink', video)
               if(result?.status_code == 200){
                   return result?.data?.url || null
               }
    
            } catch (error) {
                console.log(error)
                return '/img/app_logos/dark_app_logo.png'
            }
 
        }
        return '/img/app_logos/dark_app_logo.png'

    },

        getModifiedDate(date){
            return moment(date).format('DD/MM/YYYY HH:mm');
        },
        
        async getAds(){
            try {
                const lang = this.getSelectedLanguage;
                this.adsListFilter['lang']= lang;
              const ads = await this.$store.dispatch('adminUserModule/getAdminAdsList',this.adsListFilter);
              for(const ad of ads){
                const banner  = ad?.image?.bucketLocation || ad?.videoBanner?.bucketLocation || null;
                const video = ad?.video?.bucketLocation || null;
                if(banner ){
                    ad.image.bucketLocation = await this.setAdImageLink(banner)
                }
                else {
                    ad.image.bucketLocation = '/img/app_logos/dark_app_logo.png'
                }

                if(video){
                    ad.video.bucketLocation = await this.setAdVideoLink(video)
                }
                
              }
              const adsFields = Object.keys(ads.length ? ads[0] : {});
              this.titlesAds = this.titlesAds.filter(title => adsFields.includes(title?.id) && this.firstSectionVisibleFields.includes(title?.id));
              this.$store.commit('adminUserModule/setAdminAdsList', ads);
  
          } catch (error) {   
              console.log(error);
              this.$store.commit('adminUserModule/setAdminAdsList', []);
              this.titlesAds = [];
          }
        },
      },
  
      async created() {
          await this.getAds()
      }
  }
  </script>
  
  <style scoped lang="scss">
  
      .even-tr {
          background: #F9FAFB !important;
      }
      .change-status-modal-btn {
        background: #D8E6E8;
        color: #515050 !important;
        font-size: .65rem;
        font-weight: 500;
        padding: .3rem .5rem;
        border: 0;
        margin-left: .2rem;
        border-radius: 6px;
        cursor: pointer;
        &:hover {
            opacity: .8;
        }


      }
      .my-ads-container {
          display: flex;
          flex-flow: column nowrap;
          align-items: flex-start;
          justify-content: flex-start;
          width: 100%;
          height: 100%;
          gap: 1rem;
  
          & p,
          h3 {
              margin: 0;
          }
  
          &__header {
              width: 100%;
              display: flex;
              flex-flow: row nowrap;
              align-items: center;
              justify-content: flex-start;
              gap: 1rem;
              border-bottom: 2px solid #dddddd6f;
              padding: .5rem 0;
          }
  
          &__list-ads {
              width: 100%;
              display: flex;
              flex-flow: row nowrap;
              align-items: flex-start;
              justify-content: flex-start;
              gap: 1rem;
  
              &__table {
  
              width: 100%;
              table-layout:auto;
              word-wrap: break-word;
              border-collapse: collapse;
  
  
              &__tr__td__image {
                  width: max-content;
                  height: max-content;
                  margin: auto;

                  

                  img {
                      width: 2rem;
                      height: 2rem;
                      object-fit: contain;
                      border-radius: 4px;
                      margin: auto;
                     
                  }
              }
  
          tr th {
              font-size: .7rem;
              color: #4f5c73 !important;
              text-transform: capitalize;
              border-radius:0;
              padding-bottom: .8rem !important;
  
  
          }
  
  
          tr td {
              text-align: center !important;
              font-size: .65rem;
              height: auto;
              padding: .6rem .3rem ;
              text-align: center;
              color: #424d63 !important;
              position: relative;
          }

          & .bx-trash {
              font-size: 1rem;
              font-weight: 550;
              color: #cf3b3b !important;
              cursor: pointer;
  
              &:hover {
                  opacity: .8;
              }
          }
  
          & i {
              font-size: 1rem;
              font-weight: 550;
              color: #4f5c73 !important;
              cursor: pointer;
  
              &:hover {
                  opacity: .8;
              }
          }
  
         tbody tr {
              width: 100%;
  
          }
  
  
                  
              }
          }
      }
  </style>