<template>
  <nav class="navbar-container">
    <div class="navbar-container__app-logo-container">
        <img src="/img/app_logos/darkest_app_logo.png" alt="app logo" class="navbar-container__app-logo-container__app-logo-img">
    </div>
    <div class="navbar-container__user-login-section">
        <div class="navbar-container__user-login-section__first">
            <span class="bx bxs-user"></span> <input type="text" :placeholder="$t('userLoginPlaceholder')" v-model="userEntryInfo" class="navbar-container__user-login-section__first__input">
            <div class="warning-message" v-if="!userEntryInfo && validationErrorUserActive">
                {{ $t('userLoginInfoWarning') }}
            </div>
        </div>
        <div class="navbar-container__user-login-section__password">
            <span class="bx bxs-lock"></span> <input type="password" @keypress.enter="loginUser" :placeholder="$t('userLoginPassword')" v-model="passwordEntryInfo" class="navbar-container__user-login-section__password__input">
            <div v-if="!passwordEntryInfo" class="navbar-container__user-login-section__password__reset">
                <span>{{ $t('userLoginResetPassword') }}</span>
            </div>

            <div class="warning-message" v-if="!passwordEntryInfo && validationErrorPasswordActive">
                {{ $t('userLoginPasswordWarning') }}
            </div>

        </div>

        <div class="navbar-container__user-login-section__login-btn">
            <button class="navbar-container__user-login-section__login-btn__btn" @click="loginUser">
                <div class="navbar-container__user-login-section__login-btn__btn__loading-animation" v-if="isLoginFetching ">
                    <img class="navbar-container__user-login-section__login-btn__btn__loading-animation__loading-icon-image" src="/img/icons/loading.gif" alt="loading">
                    <span class="navbar-container__user-login-section__login-btn__btn__loading-animation__loading-text ">{{ $t('wellDone') }}</span>
                </div>
                <span v-else>  {{ $t('userLoginTitle') }} <i class='bx bxs-right-arrow'></i> </span> </button>
        </div>

        <div class="navbar-container__user-login-section__login-error-message" v-if="loginErrorMessage">
            <p>{{ loginErrorMessage }}</p>
        </div>
    </div>
    <div class="navbar-container__user-third-section">
        <div class="navbar-container__user-third-section__sign-up">
            <button @click="isRegesterModalOpen = true" class="navbar-container__user-third-section__sign-up__btn">{{ $t('userRegisterButton') }}</button>
        </div>
        <div class="navbar-container__user-third-section__select-language">
            <n-popselect v-model:value="selectedLanguage" :options="languages">
                <div class="navbar-container__user-third-section__select-language__btn">
                    <img :src="require(`@/assets/flags/${selectedLanguage}.png`)" alt="language">
                    <span>{{ $t(selectedLanguage) }}</span>
                </div>
  </n-popselect>
        </div>
    </div>
  </nav>
  <ModalContainer v-if="isRegesterModalOpen" @closeModal="isRegesterModalOpen = false">
    <RegisterUserForm @closeModal="isRegesterModalOpen = false" />
  </ModalContainer>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalContainer from '@/utils/ModalContainer.vue';
import RegisterUserForm from '@/components/register-user/IndexUserRegister.vue'
import {throttle} from 'lodash'
import { v4 as uuidv4 } from 'uuid';

export default {

    components: {
        ModalContainer,
        RegisterUserForm
    },
    data() {
        return {
            selectedLanguage: 'tr',
            isLoginFetching: false,
            loginErrorMessage:'',
            languages: [
                {
                    label: this.$t('tr'),
                    value: 'tr'
                },
                {
                    label: this.$t('en'),
                    value: 'en'
                },


            ],
            userEntryInfo: '',
            passwordEntryInfo: '',
            validationErrorPasswordActive:false,
            validationErrorUserActive:false,
            isRegesterModalOpen: false
        }
    },

    computed: {
    ...mapGetters(['getSelectedLanguage'])
  },

  methods: {
    checkLoginvalidation() {
        if (!this.userEntryInfo) {
            this.validationErrorUserActive = true;
            setTimeout(() => {
               this.validationErrorUserActive = false; 
            }, 4000);

            return false
        }
        
        if(!this.passwordEntryInfo){
            this.validationErrorPasswordActive = true;
            setTimeout(() => {
               this.validationErrorPasswordActive = false; 
            }, 4000);

            return false

        }

        return true;
    },

    getUserInAccordingToRoleType(roleType) {
        const indRoleType = process.env.VUE_APP_INDIVIDUAL_ROLE_TYPE;
        const comRoleType = process.env.VUE_APP_COMMERCIAL_ROLE_TYPE;
        const adminRoleType = process.env.VUE_APP_ADMIN_ROLE_TYPE;
        if (roleType === indRoleType) {
            this.$router.push({ name: 'user-individual' })
        }

        if (roleType === comRoleType) {
            this.$router.push({ name: 'user-commercial' })
        }

        if (roleType === adminRoleType) {
            this.$router.push({ name: 'user-admin' })
        }
    },

    setLoginErrorMessage() {
        this.loginErrorMessage = this.$t('loginErrorMessage');

        setTimeout(() => {
            this.loginErrorMessage = '';
        }, 4000);
    },

    subscribeChannels(roleType,userId) {
        const indRoleType = process.env.VUE_APP_INDIVIDUAL_ROLE_TYPE;
        const comRoleType = process.env.VUE_APP_COMMERCIAL_ROLE_TYPE;
        const adminRoleType = process.env.VUE_APP_ADMIN_ROLE_TYPE;
        let channels = [];
        if (roleType === indRoleType) {
            channels = ["balance", "notifications","luckmecount",'logout','ad:consumed'];
        }

        if (roleType === comRoleType) {
            channels = ["balance", "notifications",'logout'];
        }

        if (roleType === adminRoleType) {
            channels = [ "notifications",'logout'];
        }
      this.$sse.connect(userId, channels);
    },
    setSessionId(){
        let sessionId =  uuidv4();
        this.$store.commit('setSessionId', sessionId);
    },
    loginUser: throttle(async function(){
        try {
            if(!this.checkLoginvalidation()) return;
            this.isLoginFetching = true
        const result = await this.$store.dispatch('login', {
             emailOrMemberId: this.userEntryInfo,
             password: this.passwordEntryInfo
        })
        if(result){
            const userId = result?.userId || 'random';
            const role = result?.role || '';
            this.setSessionId();  

            this.subscribeChannels(role,userId);
            this.getUserInAccordingToRoleType(role)

        }
        else {
            this.setLoginErrorMessage();
            this.isLoginFetching = false
            return false
        }
        
        } catch (error) {
            this.setLoginErrorMessage();
            console.log(error)
        }
        finally {
            this.isLoginFetching = false
        }

    }, 1000),
  },

  created(){
    this.selectedLanguage = this.getSelectedLanguage
  },

    watch: {
        selectedLanguage() {
            this.$store.commit('setSelectedLanguage', this.selectedLanguage)
        }
    },

}
</script>

<style scoped lang="scss">

.warning-message {
    color: #ba1b1b;
    font-size: .8rem;
}

.navbar-container {
    display: flex;
    width: 100%;
    height: max-content;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    padding: .6rem 8%;
    background: #FFCC01;

    @media screen and (max-width: 450px) {
            padding: .6rem 2%;
           
        }

    &__app-logo-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 5rem;

        @media screen and (max-width: 450px) {
            display: none;
        }
        &__app-logo-img {
            width: 5rem;
            height: 5rem;
            object-fit: scale-down;
            
        }
    }

    &__user-login-section {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        gap: 1.5rem;
        width: 50%;
        margin: 0;
        margin-left: 1rem;

        @media screen  and (max-width: 600px) {
            width: max-content;
        }

        @media screen  and (max-width: 450px) {
            margin-left: 0;
            gap: .5rem;
        }


        &__login-error-message {
            position: absolute;
            bottom: -3.5rem;
            right: 0;
            width: max-content;
            height:auto;
            background: #ffecec;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: .2rem .6rem;
            color: #fd1616;
            font-size: .75rem;
            font-weight: 550;
            box-shadow: 1px 1px 2px 0px #ab08081a;
        }

        &__first {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #F5F4F4;
            color: #535353;
            border-radius: 4px;
            border:1px solid #D9D9D9;

            & .bxs-user {
                font-size: 1.1rem;
                margin:auto .6rem;
            }
            &__input {
                width: 12rem;
                height: 1.8rem;
                border: none;
                outline: none;
                border-radius: 4px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                padding: .2rem .5rem;
                font-size: .8rem;

                @media screen  and (max-width: 450px) {
                    width: 10rem;
                    font-size: .7rem;
        }


                &:focus {
                    outline: none;
                }

                &::placeholder {
                    color: #999999;
                }
            }
        }
        &__password {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: max-content;
            background: #F5F4F4;
            color: #535353;
            border-radius: 4px;
            border:1px solid #D9D9D9;
            position: relative;

            & .bxs-lock {
                font-size: 1.1rem;
                margin:auto .6rem;
            }
            &__input {
                width: 11rem;
                height: 1.8rem;
                border: none;
                outline: none;
                border-radius: 4px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                padding: .2rem .5rem;
                font-size: .8rem;

                @media screen  and (max-width: 450px) {
                    width: 9rem;
                    font-size: .7rem;
        }

                &:focus {
                    outline: none;
                }

                &::placeholder {
                    color: #999999;
                }
            }
            &__reset {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: .7rem;
                color: #ffffff;
                font-weight: 550;
                background: #535353;
                position: absolute;
                right: .5rem;
                top: 50%;
                border-radius: 6px;
                transform: translateY(-50%);
                padding: .3em .9em;

                @media screen  and (max-width: 450px) {
                    border-radius: 2px;
        }

                &:hover {
                    cursor: pointer;
                    opacity: .8;
                }
            }
        }
        &__login-btn {
            display: flex;  
            align-items: center;
            justify-content: center;
            &__btn {
                display: flex;
                align-items: center;
                justify-content: center;
                width: max-content;
                height: max-content;
                border: none;
                outline: none;
                border-radius: 4px;
                background-color: #000;
                color: #FFCC01;
                font-size: .9rem;
                font-weight: 650;
                padding: .6rem 1rem;
                gap: .4rem;

                &__loading-animation {
                    display: flex;
                    flex-flow: wrap;
                    align-items: center;
                    justify-content: center;

                    &__loading-icon-image {
                        width: 1.3rem;
                        height: 1.3rem;
                        object-fit: scale-down;
                        margin-right: .2rem;
                    }

                    &__loading-text {
                        font-size: .8rem;
                        font-weight: 550;
                    }
                }

                @media screen  and (max-width: 450px) {
                    font-size: .8rem;
                    padding: .4rem .8rem;
                    border-radius: 2px;

        }

                &:hover {
                    cursor: pointer;
                    opacity: .8;
                }


                & .bxs-right-arrow {
                    margin-top: .1rem;
                }
            }
        }
    }
    &__user-third-section {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        width: 30%;
        gap: 1.5rem;
        margin: auto;
        @media screen and (max-width: 600px) {
            width: 100%;
            justify-content: flex-start;
            margin-left: 0;
            margin-top: 1rem;
        }
        &__sign-up {
            &__btn {
                width: max-content;
                height: max-content;
                border: none;
                outline: none;
                border-radius: 6px;
                background-color: #ffffff;
                color: #000000;
                border-radius: 1px solid #D9D9D9;
                font-size: 1rem;
                font-weight: 600;
                padding: 1rem 1rem;
                gap: .4rem;

                @media screen and (max-width: 600px) {
                    border-radius: 2px;
        }

                &:hover {
                    cursor: pointer;
                    opacity: .8;
                }
            }
        }
        &__select-language {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: .5rem;
            padding-top: .2rem;

            
            &__btn {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                justify-content: flex-start;
                width: max-content;
                height: max-content;
                border: none;
                outline: none;
                border-radius: 0;
                color: #000000;
                border-radius: 1px solid #D9D9D9;
                font-size: .8rem;
                font-weight: 550;
                padding: 0;
                gap: .3rem;
                padding:0 .6rem .2rem .6rem;
                // border-bottom: 1px solid #000000;


                & img {
                    width: 1.4rem;
                    height: 1.4rem;
                    margin-right: .3rem;
                }
            }
        }
    }

}
</style>