

const tr = {

    userLoginButton: "Giriş Yap",
    userLogoutButton: "Çıkış Yap",
    userLoginTitle: "Giriş Yap",
    userRegisterTitle: "Kazanmak için Kayıt Ol",
    userRegisterButton: "Kayıt Ol",
    userRegisterRole: "Kullanıcı Tipi",
    userRegisterName: "Ad",
    userRegisterSurname: "Soyad",
    userRegisterEmail: "Email",
    userRegisterPassword: "Sifre",
    userRegisterIBAN: "IBAN",
    userRegisterCountry: "Ulke",
    userRegisterBirthdate: "Dogum Tarihi",
    userRegisterRoleError: "Lutfen bir kullanıcı tipi seciniz!",
    userRegisterBirthdateError: "En az 18 yas olmalıdır!",
    userRegisterIBANWarning: "IBAN sahibinin adı ve soyadı ile aynı olmalıdır!",
    userRegisterIBANError: "IBAN gecerli degil!",
    userRegisterError: "Bir hata olustu!",
    userRegisterEmailError: "Gecersiz email!",
    userRegisterPasswordError: "Gecersiz sifre!",
    userRegisterConfirmPassword: "Sifreyi Onayla",
    userRegisterConfirmPasswordError: "Sifreler uyusmuyor!",
    userLoginEmail: "Email",
    userLoginUserId: "Üye Numarası",
    userLoginUserIdError: "Geçersiz üye numarası!",
    userLoginPassword: "Sifre",
    userLoginPasswordError: "Gecersiz sifre!",
    userLoginEmailError: "Gecersiz email!",
    userLoginError: "Bir hata olustu!",
    userLoginPlaceholder: "Email veya Üye Numarası",
    userLoginResetPassword: "Unuttum",
    tr: "Türkçe",
    en: "İngilizce",
    stepsExplanation:"Reklamlara Tıklayarak Para Kazanmaya Başlayın",
    step:"Adım",
    stepOne:"FELIXLEAP'a İhtiyacınızı Uygun Bilgileri Girerek Kayıt Olun",
    stepTwo:`Reklamlara Tıklayın ve "Şansla Beni" Hakkınızı Artırın`,
    stepThree:"Şansla Beni Tuşuna Basarak Arkanıza Yaslanarak Para Kazanın",
    stepOneDetail:"Gerekli Bilgileri Girerek Kaydınızı Tamamlayın",
    stepTwoDetail:`Her Reklam Tıklamanız 1 "Şansla Beni" Hakkı Kazandırır. `,
    stepThreeDetail:"Şansla Beni Tuşuna Bastıktan Sonra 3 Eşlemeyi Yakalarsanız Kazancınız Anında Hesabınıza Yansır.",
    whyUseApp:"FELIXLEAP Zamanınızı Nasıl Daha Değerli ve Keyifli Kılar?",
    whyUseAppDetail: `Bu platformun çekiciliği, basitliğinden kaynaklanıyor. Günlük rutininize kolayca entegre olabilecek şekilde tasarlanmış bir platform sunuyoruz. Bu platform, özel becerilere veya çok fazla zamana ihtiyaç duymadan reklamlara tıklayarak para kazanmanızı sağlıyor. Reklamlara tıklarken sadece bir kullanıcı değil, aynı zamanda vaktinizin de değerini bilen ve bunun karşılığını keyifli bir şekilde alabileceğiniz bir topluluğun aktif bir üyesi oluyorsunuz. 
    <br>
    <br>
    Reklamlara tıklayarak 'Şansla Beni' hakkı kazanıyorsunuz ve bu haklarla Şansla Beni oyunu oynayarak gelir elde edebiliyorsunuz. Bu, hem para kazanma hem de keyif alma fırsatı sunan bir uygulama. Ek olarak, size özel reklamlar, promosyon kodları ve daha fazlasını sunuyoruz. Ayrıca, üyelerimiz kendi reklamlarını platformumuzda yayınlayarak kendi etkileşim kitlelerini büyütebilirler. Bu özelliklerle, hem bireysel kullanıcılar hem de işletmeler için değerli bir kaynak sunuyoruz.`,

    downloadApp:"Keyifli Kazanç İçin Uygulamamızı Deneyin",
    downloadAppDetail:"FELIXLEAP'i, Android, iOS vb. tüm cihazlarınıza hızlıca indirebilirsiniz. FELIXLEAP platformunuza kaydınızı tamamladıktan sonra, reklamlara tıklayarak kazanç elde edebilirsiniz.",
    downloadAppButton:"FELIXLEAP'i indirin",
    frequentlyAskedQuestions:"Sıkça Sorulan Sorular",
    fr_ask_1: "FELIXLEAP ile Nasıl Kazanç Elde Edilir?",
    fr_ans_1: "FELIXLEAP platformunuza kaydınızı tamamladıktan sonra, reklamlara tıklayarak 'Şansla Beni' hakkı elde ederek keyifli kazançlar elde edebilirsiniz. Her reklam tıklamanızda, bir 'Şansla Beni' hakkı kazanırsınız. İşletme hesapları içinse, özelleştirilmiş reklamlarınızı ve promosyonlarınızı uygulamamızda yayınlayarak daha geniş kitlelere uygun maliyetlerle ulaşabilirsiniz.",
    
    fr_ask_2: "FELIXLEAP Bizden Herhangi Bir Ücret Talep Ediyor Mu?",
    fr_ans_2: "Hayır. FELIXLEAP'in amacı, kullanıcılarına eğlenceli bir şekilde reklam gösterimlerinden elde edilen kazancı paylaşmaktır. Bunun dışında herhangi bir ücret talep etmez. İşletme hesapları içinse verilecek reklamın türüne, bölgesine ve hedef gösterimine bağlı olarak ücretlendirme yapılmaktadır.",
    
    fr_ask_3: "FELIXLEAP Paylaştığım Bilgileri Güvenli Bir Şekilde Koruyor Mu?",
    fr_ans_3: "Evet. FELIXLEAP, sahip olduğu özel güvenlik algoritmaları ile her türlü siber saldırıya karşı ileri düzey güvenlik önlemleri sağlamaktadır. Bilgileriniz şifrelenmiş bir şekilde saklanmaktadır.",
    
    fr_ask_4: "FELIXLEAP 'Şansla Beni' Nedir?",
    fr_ans_4: "Her reklam tıklamanızda 1 adet 'Şansla Beni' hakkı kazanırsınız. Uygulama içinde 'Şansla Beni' tuşuna bastığınız zaman 6 adet sayı içeren kart açılır. Bu 6 sayı üzerinden eşleşen 3 aynı sayı olursa, eşleşme sağlanan sayı değeri kadar anında hesabınıza para yatırılır. Örnek olarak 10, 20, 1000, 50, 1000, 1000 şeklinde bir sonuçla karşılaşırsanız, 3 aynı değeri olan 1000, bulunduğunuz bölgenin para birimi cinsinden hesabınıza yatırılır.",
    
    fr_ask_5: "Kazandığım Parayı Nasıl Çekebilirim?",
    fr_ans_5: "Kayıt olurken paylaştığınız IBAN numaranıza, bakiyeniz minimum çekim tutarına ulaştığında istediğiniz miktarda para transferi yapabilirsiniz. Örneğin, Euro Bölgesi için minimum çekim tutarı 5 EURO'dur.",
    
    fr_ask_6: "Kazancım Ne Kadar Sürede IBAN Hesabıma Geçer?",
    fr_ans_6: "Çekmek istediğiniz miktar 72 saat içinde hesabınızda olacaktır. Önemli bilgilendirme: IBAN numarasına ait isim, soyisim ve para birimi kayıt aşamasında verdiğiniz bilgilerle aynı olmalıdır. Aksi takdirde transfer işlemi gerçekleştirilmez.",
    
    fr_ask_7: "Bir Problem Yaşandığında FELIXLEAP'e Nasıl Ulaşabilirim?",
    fr_ans_7: "Uygulamamızda bulunan Destek ve Müşteri Hizmetleri sohbet kutusundan sorununuz ile ilgili yardım alabilirsiniz. Eğer sorununuz devam ederse, şirketimizin resmi e-posta adresine veya sosyal medya hesaplarına doğrudan sorununuz ile ilgili ileti gönderebilirsiniz.",
    founder: "Kurucu",
    optional: "Opsiyonel",
coFounder: "Kurucu Ortak",
allRightsReserved:"FELIXLEAP. Telif hakları saklıdır.",
userRegisterRole: "Bireysel",
userRegisterCommercialRole: "Ticari",
userRegisterFormTitle: "Kayıt",
userRegisterFormEmail: "E-posta",
userRegisterFormPassword: "Şifre",
userRegisterFormConfirmPassword: "Şifreyi tekrar giriniz",
userRegisterFormCountry: "Ülke",
userRegisterFormCity: "Şehir",
userRegisterFormPhone: "Telefon",
userRegisterFormIBAN: "IBAN",
userRegisterFormCommercialName: "Ticari Ad",
userRegisterFormName: "Adınız",
userRegisterFormSurname: "Soyadınız",
userRegisterFormCurrency: "IBAN Hesabı Para Birimi",
userRegisterFormSubmit: "Kayıt Ol",
userRegisterFormBirthdate: "Dogum Tarihi",
userRegisterFormPhoneErrorMessage:"Lütfen gecerli bir telefon numarası giriniz.",
userRegisterFormIBANErrorMessage:"IBAN gecerli degil.",
userRegisterFormEmailErrorMessage:"Lütfen gecerli bir e-posta adresi giriniz.",
userRegisterFormPasswordErrorMessage:"Lütfen gecerli bir sifre giriniz.",
userRegisterFormConfirmPasswordErrorMessage:"Şifreler uyuşmuyor.",
userResgisterFormBirthdateErrorMessage:"Lütfen doğum tarihinizi giriniz.",
userRegisterFormNameErrorMessage:"Lütfen adınızı giriniz.",
userRegisterFormSurnameErrorMessage:"Lütfen soyadınızı giriniz.",
userRegisterFormCountryErrorMessage:"Lütfen ülkenizi seçiniz.",
userRegisterFormCurrencyErrorMessage:"Lütfen IBAN hesabınızın para birimini seçiniz.",
userLoginInfoWarning: "Lütfen e-posta adresinizi veya üye numaranızı giriniz.",
userLoginPasswordWarning: "Lütfen sifrenizi giriniz.",
userIndividualMemberNo: "Üye No",
userBalance: "Bakiye",
userLoadBalance: "Para Yatır",
userIndividualWithdraw: "Para Çek",
userProfile: "Profil",
userSettings: "Ayarlar",
userNotifications: "Bildirimler",
userProfileEdit: "Profilini Düzenle",
userProfileEditSubmit: "Kaydet",
userProfileEditCancel: "Vazgeç",
userProfileEditDelete: "Hesabınızı Sil",
userPublishAds: "Reklam Yayınla",
userMyAds: "Reklamlarım",
userAnalytics: "Analizler",
publishAds:"Reklam Yayınla",
publishAdsDetail:"Reklamınızı FELIXLEAP ile yayınlayın ve  herkes tarafından görülür olun.",
stepContent:"İçerik",
stepLocation:"Konum",
stepBudget:"Bütçe",
language:"Dil",
and:'ve',
create:'Oluştur',
set:'Ayarla',
select:'Seç',
Upload:'Yükle',
uploadMediaImages:'Resim Yükle',
countries:"Ülkeler",
languages:"Diller",
categories:"Kategoriler",
countriesPlaceHolder:"Ülke Seçin",
languagesPlaceHolder:"Dil Seçin",
cityPlaceHolder:"Şehir Seçin",
categoriesPlaceHolder:"Kategori Seçin",
contentLink:"İçerik Linki",
promotionCode:"Promosyon Kodu",
titlePlaceHolder:"Baslık Girin",
descriptionPlaceHolder:"Tanım Girin",
linkPlaceHolder:"Link Girin",
promotionCodePlaceHolder:"Promosyon Kodu Girin",
budgetPlaceHolder:"Bütçe Girin",
budgetError:"Lütfen bütçeyi giriniz.",
titleError:"Lütfen başlık giriniz.",
descriptionError:"Lütfen tanım giriniz.",
linkError:"Lütfen link giriniz.",
promotionCodeError:"Lütfen promosyon kodu giriniz.",
contentError:"Lütfen içerik giriniz.",
locationError:"Lütfen konum seçiniz.",
uploadImageError:"Resim yükleme sırasında bir hata oluştu. Lütfen tekrar deneyin.",
uploadImageAtLeastOne:"Lütfen en az bir resim yükleyin.",
countriesError:"Lütfen ülke seçiniz.",
languagesError:"Lütfen dil seçiniz.",
categoriesError:"Lütfen kategori seçiniz.",
budget:"Bütçe",
budgetError:"Lütfen bütçenizi giriniz.",
setBudget:"Toplam Bütçeniz",
setBudgetError:"Lütfen bütçenizi seçiniz.",
currency:"Para Birimi",
currencyError:"Lütfen para birimi seçiniz.",
currencyPlaceholder:"Para Birimi Seçin",
adsBudgetAmountPlaceholder:"Lütfen Bütçenizi Girin",
totalCost:"Toplam Maliyet",
setViewCount:"Hedeflenen İzlenme Sayısı",
setViewPlaceholder:"Hedeflenen izlenme sayısını girin",
minTargetedViews:"Minimum Hedeflenen İzlenme",
minTargetedViewsError:"Minimum 100 hedeflenen izlenme girmelisiniz.",
setShowTime:"Reklam Gösterim Süresi (saniye)",
setShowTimePlaceholder:"Reklam gösterim süresini girin",
setShowTimeError:"Lütfen reklam gösterim saniyesini minimum 5 saniye olarak seçin.",
watching:"görüntülenmenin",
watchingSec:"saniye boyunca izlenmesinin maliyeti",
ageLimitation:"Reklamınıza 18 Yaş Kısıtı Uygulansın mı?",
no:'Hayır',
yes:'Evet',
keywords:"Anahtar Kelimeler",
keywordsPlaceHolder:"Anahtar Kelimeleri Girin",
uploadImage:"Resim Yükle",
myAds:"Yayınlan Reklamlarım",
title:"Başlık",
description:"Tanım",
image:"Resim",
banner:"Afiş",
categories:"Kategoriler",
countries:"Ülkeler",
cities:"Şehirler",

languages:"Diller",
link:"Link",
status:"Durum",
targetedViewsCount:"Hedeflenen İzlenme",
reachedViewsCount:"Ulaşılan İzlenme",
approval:"Onay Durumu",
approved:"Onaylandı",
rejected:"Reddedildi",
pending:"Beklemede",
active:"Aktif",
inactive:"İnaktif",
deactivate:"İnaktif Et",
activate:"Aktif Et",
more:"Detay",
edit:"Düzenle",
delete:"Sil",
view:"Görüntüle",
deleteAd:"Reklamı Sil",
deleteAdDetail:"Reklamı silmek istediğinizden emin misiniz?",
cancel:"Vazgeç",
yes:"Evet",
no:"Hayır",
userAdminMemberNo:"Üye No",
copied:"Kopyalandı",
adsManagement:"Reklam Yönetimi",
generalManagement:"Genel Yönetim",
change:"Değiştir",
changeStatus:"Değişim Durumu",
changedSuccess: 'Değişim Gerçekleştirildi.',
commercialName:"Ticari Adı",
adTitle:"Reklam Başlığı",
userId:"Kullanıcı No",
adminUserToSeeDetails:"Reklam Durumu",
adsManagement:"Reklam Yönetimi",
over18:"18 Yaş Üstü",
publishDate:"Yayınlanma Tarihi",
createdDate:"Oluşturulma Tarihi",
statusDetail:"Durum Detayı",
category:"Kategori",
noCategory:"Kategori mevcut değil.",
noName:"İsim mevcut değil.",
noDescription:"Tanım mevcut değil.",
noLink:"Link mevcut değil.",
noPromotionCode:"Promosyon Kodu mevcut değil.",
noContent:"İçerik mevcut değil.",
noLocation:"Konum mevcut değil.",
noBudget:"Bütçe mevcut değil.",
noCurrency:"Para Birimi mevcut değil.",
noAds:"Reklam mevcut değil.",
noImage:"Resim mevcut değil.",
noDate:"Tarih mevcut değil.",
update:"Güncelle",
messagesToAdStatusPlaceholder:"Reklam durumunun dönütünü girin",
adStatusMessageRequired:"Reklam durumunun dönütünü girmelisiniz.",
adStatusUpdated:"Reklam durumu güncellendi.",
linkPreview:"Link Önizleme",
visitTo:"Ziyaret Et",
fav:"Favorilere Ekle",
share:"Paylaş",
report:"Raporla",
complete:"Tamamla",
closeAd:"Reklamı Kapat",
reportDetail:"Reklamı raporlamak istediğinizden emin misiniz?",
reportSuccess:"Raporlama Gerçekleştirildi",
reportError:"Raporlama Hatalı",
reportSuccessDetail:"Raporlama Gerçekleştirildi",
IlikeIt:"Beğendim",
notSure:"Emin değilim",
reportAd:"Reklamı Raporla",
logout:"Çıkıs Yap",
userCommercialMemberNo:"Yayıncı Üye No",
accordingTopublishDate:"Yayınlanma Tarihine Göre",
moreFilter:"Diğer Filtreler",
luckyMeGame:"Şansla Beni Oyunu",
luckyMeGameIntro:"3 eşleşmeyi yakala ve ödülü kazan!",
luckyMe:"Şansla Beni",
publisher:"Yayıncı",
viewer:"İzleyici",
userRegisterFormPublisherName:"Yayıncı Adı",
userRegisterFormPublisherNamePlaceholder:"Yayıncı Adınızı giriniz",
userRegisterFormPublisherEmail:"Yayıncı Email",
userRegisterFormPublisherEmailPlaceholder:"Yayıncı Email adresinizi giriniz",
paymentSectionTitle:'Ödeme Aşaması',
paymentIntentPlaceHolder:'Yüklenecek Miktar',
cardInformationTitle:'Kart Bilgileri',
cardNumberExamplePlaceholder:'4242 4242 4242 4242',
expireDateMonthExamplePlaceholder:'AA',
expireDateYearExamplePlaceholder:'YY',
cvcExamplePlaceholder:'CVC',
cardNumberError:'Lütfen kart numarasını girin.',
expireDateError:'Lütfen kart sona erme tarihini girin.',
cvcError:'Lütfen cvc numarasını girin.',
cardNumberPlaceholder:'Kart Numarası',
cardHolderNamePlaceholder:'Kart Sahibinin Adı',
cardHolderName:'Kart Sahibinin Adı',
cardHolderNameError:'Lütfen kart sahibinin adını girin.',
userRegisterFormCountry:'Ülke',
billingAddress:'Fatura Adresi',
billingAddressPlaceholder:'Lütfen fatura adresinizi girin',
billingAddressError:'Lütfen fatura adresinizi girin.',
countryError:'Lütfen bir ülke seçin.',
userRegisterFormCountryPlaceholder:'Lütfen bir ülke seçin',
isBusinessPurchase:'İşletme olarak satın alıyorum',
vatNumberTitle:'VKN Bilgileri',
vatNumberPlaceholder:'VKN Numarası',
vatNumber:'VKN Numarası',
vatNumberError:'Lütfen VKN numarasını girin.',
businessNamePlaceholder:'İşletme Adı',
businessName:'İşletme Adı',
businessNameError:'Lütfen İşletme adını girin.',
makePayment:'Ödemeyi Yap',
addBalance:'Bakiye Ekle',
balanceError:'Bakiye Ekleme İşlemi Basarısız. Lütfen tekrar deneyin.',
addBalanceError:'Lütfen bakiye miktarını girin.',
addBalanceSuccess:'Bakiye Başarıyla Eklendi',
supportedBy:'Tarafından Desteklenmektedir',
supportedByStribe:'<strong>Stribe</strong> Tarafından Desteklenmektedir.',
terms:"Şartlar",
privacy:"Gizlilik",
conditions:"Koşullar",
balanceLoadDescription:"Hesabınıza bakiye ekleme.",
successPaymentTitle:"Ödeme İşlemi Başarılı!",
successPaymentDescription:"Ödeme işleminiz başarıyla gerçekleştirildi.",
totalPayment:"Toplam Ödeme",
goToCreateAdSection:"Reklam Yayınlaya Git",
subCategories:"Alt Kategoriler",
categoriesSubPlaceHolder:"Alt Kategori Seçin",
stepBudgetAndTarget:"Bütçe & Hedef",
target:"Hedef",
male:   "Erkek",
female:"Kadın",
all:"Hepsi",
ageGap:"Yaş Aralığı",
minPerUserView:"Kullanıcı Başı İzlenme",
setMinPerUserView:"Minumum İzlenme Sayısı",
targetedGender:"Hedeflenen Cinsiyet",
seeCost:"Maliyeti Göster",
enterViewsCountError:"İzlenme sayısı minimum 100 olmalıdır.",
enterAdShowSecError:"Reklam gösterim saniyesi minimum 10 saniye olmalıdır.",
enterCountriesError:"Lütfen en az bir ülke seçin.",
enterCategoriesError:"Lütfen en az bir kategori seçin.",
luckyNumbers:"Şanslı Sayılar",
remainingLuckmeAttempts:"Şansla Beni Hakkın",
totalEarning:"Toplam Kazanç",
userRemainingAdsDailyCount:"Kalan Etkileşim Hakkı",
preview:"Önizleme",
draft:"Taslak",
back:"Geri",
userRegisterGender:"Cinsiyet",
male:"Erkek",
female:"Kadın",
other:'Diğer',
areYouSureDraft:"Taslak olarak kaydedilsin mi?",
invalidFormat: "Geçersiz Format!",
imageFormatError: "Resim JPG, PNG, JPEG, WEBP, SVG veya GIF formatında olmalıdır.",
fileSizeToLarge: "Dosya boyutu çok büyük!",
fileSizeLimit: "Resim dosya boyutu 2MB'den küçük olmalıdır.",
invalidDimensions: "Geçersiz Boyutlar!",
invalidDimensionsError: "Resim boyutları en az",
videoFormatError: "Video MP4, WEBM veya OGG formatında olmalıdır.",
videoDurationError: "Video Çok Uzun!",
videoDurationError2: "Video süresi en fazla",
seconds: "saniye olmalıdır.",
videoProcessingError: "Video dosyası işlenirken bir hata oluştu.",
fileSizeVideoLimit: "Video dosya boyutu 10MB'den küçük olmalıdır.",
wellDone:"Tebrikler \uD83D\uDE0E",
loginErrorMessage:"Kullanıcı adı veya parolan yanlış. Hangisi ama söylemem \uD83D\uDE22",



















}



export default tr;