<template>

  <router-view :key="currentLang"/>
</template>
<script>
import { mapGetters } from 'vuex';
import activeSupportedLang  from '@/assets/languages/supportedSystemLanguage.json'
import getBrowserInfo from '@/utils/getUserBrowserInfo'

export default {
  data() {
    return {  
      currentLang: 'tr',
    }
  },
  computed: {
    ...mapGetters(['getSelectedLanguage','getEventSource'])
  },

  methods:{
    
    setExistedEventSource(){
      if(this.getEventSource){
        const userId = this.getEventSource?.userId || '';
        const channels = this.getEventSource?.channelArray || [];
        this.$sse.connect(userId, channels);
      } 
    },
    setDeviceScreenType() {

const currentWidth = window.innerWidth || window.screen.width || 0;
      let screenType = null;

if(currentWidth > 2560){
  screenType = 'xxlarge'
}
else if(currentWidth <= 2560 && currentWidth > 1440){
  screenType = 'xlarge'
}
else if(currentWidth <= 1440 && currentWidth > 1024){
  screenType = 'large'
}
else if(currentWidth <= 1024 && currentWidth > 768){
  screenType = 'medium'
}
else if(currentWidth <= 768 && currentWidth > 512){
  screenType = 'small'
}
else if (currentWidth <= 512 ){ 
  screenType = 'xsmall'
}


this.$store.commit('setScreenType', screenType);
},
    setBrowserInfo(){
        const userAgentInfo = navigator?.userAgent || navigator?.vendor || window?.opera;
        let browserInfo = getBrowserInfo(userAgentInfo);
        this.$store.commit('setBrowserInfo', browserInfo);
    },
    setDefaultBrowserLanguage(){
      const browserLanguage = navigator.language || navigator.userLanguage;
      if(activeSupportedLang.includes(browserLanguage.toLowerCase())){
        this.$store.commit('setSelectedLanguage', browserLanguage.toLowerCase())
      }
      else{
        this.$store.commit('setSelectedLanguage', 'en')
      }

    }
  },

  mounted(){
    this.setDeviceScreenType();
    this.setBrowserInfo();
    this.setExistedEventSource();
    },

  watch: {
    "getSelectedLanguage":{
      handler(lang) {
      this.$i18n.locale = lang
      this.currentLang = lang;

      },
      deep:true

    }
  }
}
</script>
<style lang="scss">
body {
  margin: 0 !important;
  padding: 0 !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;

 

}

</style>
