<template>
  <div class="ads-container" >
    <CarouselAdContainer  v-if="isTabletOrMobile" class="ads-container__cards" :getDymcAds="getDymcAds" @setAdDetailModel="setAdDetailModel($event)" :showAdCount="showAdCount" :isTabletOrMobile="isTabletOrMobile" :isFetchingAds="isFetchingAds" />

    <div class="ads-container__cards" v-else>
      <AdCard v-for="(ad,index) in getDymcAds" :index="index" :key="ad?.id" :adDetail="ad" @setAdDetailModel="setAdDetailModel($event)"/>
    </div>

  </div>

</template>

<script>
import { mapGetters } from 'vuex';
import AdCard from "@/components/indivudal-user-section/AdCardContainer.vue"
import CarouselAdContainer from "./CarouselAd.vue"
import { shuffle } from 'lodash';

import { Carousel, Slide , Navigation} from 'vue3-carousel'
  import 'vue3-carousel/dist/carousel.css'


export default {

  components: {
    AdCard,
    CarouselAdContainer,
    Carousel,
    Navigation,
    Slide

  },
  computed:{

    ...mapGetters({getUserIndividualInfo:'individualUserModule/getUserIndividualInfo',
    getDailyWatchedAds:'individualUserModule/getDailyWatchedAds',
     getSelectedLanguage:'getSelectedLanguage',
     getInitialAds:'individualUserModule/getInitialAds',
     getPendingAds:'individualUserModule/getPendingAds'}),
     getDymcAds(){
      let initialAds = [...this.getInitialAds]
      return shuffle(initialAds).slice(0, 6)
     },
  },
  data() {
    return {
      filter:{},
      isFetchingAds:false,
      isTabletOrMobile: false,
      showAdCount:6,


    }
  },

  mounted() {
    this.setIsTabletOrMobile();
    // document.addEventListener('DOMContentLoaded', this.setIsTabletOrMobile);
    // window.addEventListener('resize', this.setIsTabletOrMobile);
  },
  unmounted() {
    // document.removeEventListener('DOMContentLoaded', this.setIsTabletOrMobile);
    // window.removeEventListener('resize', this.setIsTabletOrMobile);
  },



  methods: {

    setIsTabletOrMobile() {

      const currentWidth = window.innerWidth || window.screen.width || 0;
      if(currentWidth <= 768 && currentWidth > 650){
        this.isTabletOrMobile = true
        this.showAdCount = 3
      }
      else if (currentWidth <= 650 && currentWidth > 500){ 
        this.isTabletOrMobile = true
        this.showAdCount = 2
      }
      else if(currentWidth <= 500 && currentWidth > 0){

        this.isTabletOrMobile = true
        this.showAdCount = 1

      }
      else {
        
        this.isTabletOrMobile = false
        this.showAdCount = 6

      }
    },

    
    setAdDetailModel(adDetail){

      this.$emit('setAdDetailModel',adDetail)
    },

 

    async fetchPendingAdsList(){
      try {
        const userInfo = this.getUserIndividualInfo || null;
        const initialAds = this.getInitialAds || [];
        const activeAdIds = initialAds.map(ad => ad._id);

        this.$store.dispatch('individualUserModule/getUserIndividualPendingAds',{
                userInfo,
                filter:this.filter,
                userWatchedAds:this.getDailyWatchedAds,
                activeAdIds,
                lang:this.getSelectedLanguage
              })
      } catch (error) {
        console.log(error);
      }
    },
    async fetchInitialAdsList(){
        try {
          this.isFetchingAds = true
          const userInfo = this.getUserIndividualInfo || null;
          const initialAds = await this.$store.dispatch('individualUserModule/getUserIndividualInitialAds',{
            userInfo,
            userWatchedAds:this.getDailyWatchedAds,
            filter:this.filter,
            lang:this.getSelectedLanguage
          });

          if(initialAds?.length){
                const activeAdIds = initialAds.map(ad => ad._id);
                await this.$store.dispatch('individualUserModule/getUserIndividualPendingAds',{
                userInfo,
                filter:this.filter,
                userWatchedAds:this.getDailyWatchedAds,
                activeAdIds,
                lang:this.getSelectedLanguage
              })

          }
        } catch (error) {
          console.log(error);
        }
        finally{
          this.isFetchingAds = false
        }
    }
  },

  async created(){
    if((this.getInitialAds || [])?.length === 0){
      await this.fetchInitialAdsList()
    }
    else if((this.getInitialAds || [])?.length < 6){
       this.fetchPendingAdsList()
    }


   
  }
}
</script>

<style scoped lang="scss">


.ads-container{

    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    height: 31rem;
    background: #ffffff;
    border: 0;
    // padding: 1rem 2%;


  box-sizing: border-box;
  border-radius: 6px;

  @media screen and (max-width: 768px) {
        height:max-content;
    }

  //   @media screen and (max-width: 600px) {
  //       height:26rem;
  //   }

  //   @media screen and (max-width: 400px) {
  //       height:26.5rem;
  //   }



    &__cards{
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2%;
    @media screen and (max-width: 768px) {
        justify-content: space-between;
        gap:0;
    }
    }
}
</style>