<template>
    <div class="admin-user-to-see-details-container">

        <div class="admin-user-to-see-details-container__header-title">
            <h3>{{ $t('adminUserToSeeDetails') }}</h3>
        </div>

        <div class="admin-user-to-see-details-container__first-section">

            <div class="admin-user-to-see-details-container__first-section__commercial-title">
                <label for="">{{ $t('commercialName') }}:</label>
                <p>{{ adDetails?.commercialName || 'no-name' }}</p>

            </div>
            <div class="admin-user-to-see-details-container__first-section__commercial-user-id">
                <label for="">{{ $t('userId') }}:</label>
                <p> {{ adDetails?.userId || 'no-user-id' }}</p>

            </div>
            <div class="admin-user-to-see-details-container__first-section__commercial-ad-title">
                <label for="">{{ $t('adTitle') }}:</label>
                <p v-if="(adDetails?.title || '').length < 30"> {{ adDetails?.title || 'no-title' }}</p>
                <p v-else >
                    <ShowShorterTextView  :text="adDetails?.title || 'no-title'" />

                </p>

            </div>

            <div class="admin-user-to-see-details-container__first-section__commercial-ad-over18">
                <label for="">{{ $t('over18') }}:</label>
               <p> {{ adDetails?.isOver18 ? $t('yes') : $t('no') }}  </p> 
            </div>

            <div class="admin-user-to-see-details-container__first-section__commercial-ad-budget">
                <label for="">{{ $t('budget') }}:</label>
                <p> <span>{{ adDetails?.viewCost }} </span> <span>{{ adDetails?.currency }}</span></p>
            </div>





        </div>
        <div class="admin-user-to-see-details-container__second-section">
            <div class="admin-user-to-see-details-container__second-section__commercial-ad-description">
                <label for=""> {{ $t('description') }}:</label>
                <p >
                    {{ adDetails?.description || 'no-description' }}
                </p>


            </div>
            <div class="admin-user-to-see-details-container__second-section__targeted-locations">
                <div class="admin-user-to-see-details-container__second-section__targeted-locations__countries">
                    <label for="">{{ $t('countries') }}</label>
                    <p v-html="getListBySpanTag(adDetails?.countries || 'no-countries')" :style="{justifyContent: (adDetails?.countries || '').length ? 'flex-start' : 'center'}"></p>
                </div>
                <div class="admin-user-to-see-details-container__second-section__targeted-locations__cities">
                    <label for="">{{ $t('cities') }}</label>
                    <p v-html="getListBySpanTag(adDetails?.cities || 'no-cities')" :style="{justifyContent: (adDetails?.cities || '').length ? 'flex-start' : 'center'}"></p>
                </div>
            </div>
            <div class="admin-user-to-see-details-container__second-section__status">
                <button class="status-btn" v-for="status in statusTypes" :key="status.type" :class="[getAdStatusClass(status.type)]"
                    @click="changeStatus(status.type)">
                    {{ $t(status.type) }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {

    props: {
        adDetails: {
            type: Object,
            required: true,
            default: {}
        }
    },

    data() {
        return {
            selectedStatus: 'pending',
            statusTypes: [
                {
                    type: 'approved',
                    className: 'btn-approved'
                },

                {
                    type: 'pending',
                    className: 'btn-pending'
                },

                {
                    type: 'rejected',
                    className: 'btn-rejected'
                }




            ]
        }
    },

    computed: {

    },

    methods: {

        getListBySpanTag(text) {
            return text.split(',').map(value =>{
                return `<span>${value}</span>`
            })
        },
        getAdStatusClass(type) {
            const typeSelected = this.statusTypes.find(el => el.type === type).className || 'btn-pending';
            const parsedType = typeSelected.split('-')[1];
            if(parsedType === this.selectedStatus) return typeSelected;
            else return ''

        },
        changeStatus(status) {
            this.selectedStatus = status
            this.$emit('setAdStatus',status)
        }
    },
    created() {
        this.selectedStatus = this.adDetails?.statusApproval || 'pending'
    },

}
</script>

<style scoped lang="scss">
p {
    margin: 0 !important;
    padding: 0 !important;
}

.btn-approved {
    background: #459f4d !important;
    color: #fff !important;
}

.btn-pending {
    background: #fff5bf !important;
    color: #5c5c5c !important;
}

.btn-rejected {
    background: #dc6969 !important;
    color: #fff !important;

}


.admin-user-to-see-details-container {
    display: flex;
    position: relative;
    width: 100%;
    height: max-content;
    flex-flow: column nowrap;
    padding: 0;
    align-items: center;
    justify-content: flex-start;
    gap: .5rem;
    // border-bottom: 1px solid #D9D9D9;
    background: #fff;

    &__header-title {
        display: flex;
        flex-flow: row wrap;
        width: max-content;
        height: max-content;
        margin: 0;
        margin-top: 1.5rem;
        justify-content: flex-start;
        margin-right: auto;


        & h3 {
            font-size: .8rem;
            font-weight: 700;
            color: #2A2A2A;
            margin: 1rem 0 .5rem auto !important;

        }
    }

    &__first-section {
        display: flex;
        width: 100%;
        height: max-content;
        flex-flow: row;
        align-items: center;
        justify-content: space-between;
        background: #fafafa;
        border: 0;
        border-radius: 4px;
        padding: .5rem;
        box-sizing: border-box;


        &__commercial-title,
        &__commercial-user-id,
        &__commercial-ad-title,
        &__commercial-ad-budget,
        &__commercial-ad-over18 {
            display: flex;
            width: max-content;
            height: max-content;
            flex-flow: row;
            align-items: center;
            justify-content: flex-start;
            gap: .2rem;


            & label {
                font-size: .65rem;
                font-weight: 650;
                color: #2A2A2A;
                text-transform: capitalize;
            }

            & p {
                font-size: .65rem;
                font-weight: 500;
                color: #2A2A2A;
                text-transform: capitalize;
            }
        }
    }

    &__second-section {
        display: flex;
        width: 100%;
        height: max-content;
        flex-flow: row nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        box-sizing: border-box;
        gap: 2%;
        max-height: 8rem;



        & label {
            font-size: .7rem;
            font-weight: 650;
            color: #2A2A2A;
            text-transform: capitalize;
        }

        & p {
            font-size: .65rem;
            font-weight: 500;
            color: #2A2A2A;
            text-transform: capitalize;
        }

        &__commercial-ad-description {
            display: flex;
            width: 36%;
            height: 80%;
            overflow: auto;
            flex-flow: row nowrap;
            align-items: baseline;
            justify-content: flex-start;
            gap: .5rem;
            background: transparent;
            border: 0;
            border-radius: 8px;
            padding: .5rem;
            border: 1px solid #dfdfdf;



            & p {
                font-size: .65rem;
                font-weight: 500;
                color: #2A2A2A;
                text-transform: capitalize;
                text-align: justify;
                width: 90%;

            }

            & label {
                font-size: .7rem;
                font-weight: 650;
                color: #2A2A2A;
                text-transform: capitalize;
            }
        }

        &__targeted-locations {
            display: flex;
            width: 40%;
            height: 80%;
            overflow: auto;
            flex-flow: row wrap;
            align-items: flex-start;
            justify-content: space-between;
            // background: #f4f4f4;
            border: 0;
            border: 1px solid #dfdfdf;
            padding: .5rem 1rem;
            border-radius: 8px;


            &__cities {
                border-left: #f3f3f3 1px solid;
            }

            &__countries,
            &__cities {
                display: flex;
                width: 45%;
                height: 100%;
                flex-flow: column nowrap;
                align-items: center;
                justify-content: flex-start;
                gap: .5rem;
            
                & p {
                    width: 100%;
                    height: auto;
                    max-height: 10rem;
                    display: flex;
                    flex-flow: row wrap;
                    align-items: flex-start;
                    justify-content: flex-start;   
                    text-align: justify;          
                    overflow-y: auto;
                    padding: 1rem;

                }

                // padding: 0 .2rem;
            }
        }

        &__status {
            display: flex;
            width: 18%;
            height: 80%;
            flex-flow: column nowrap;
            align-items: flex-end;
            justify-content: flex-start;
            padding: 0;


            & button {
                display: flex;
                width: 6rem;
                height: max-content;
                flex-flow: row;
                align-items: center;
                justify-content: center;
                gap: .5rem;
                border: 0;
                border-radius: 4px;
                padding: .4rem .8rem;
                font-size: .65rem;
                font-weight: 550;
                color: #878787;
                text-transform: capitalize;
                margin-bottom: .5rem;
                background: #f6f6f6;
                cursor:pointer;

                &:hover {
                    opacity: .8;
                }

            }
        }
    }

}</style>