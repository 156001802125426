
<template>

    <div class="success-payment-container">

        <UserCommercialNavbar />
        <div class="success-payment-content" v-if="getPaymentSessionId && paymentDetails">   
            <div class="success-icon">
                <img  src="/app_icons/check.png" alt="check icon">
            </div>
            <h4 class="success-payment-title">
                {{ $t('successPaymentTitle') }}

            </h4>
            <p class="success-payment-description">
            {{ $t('successPaymentDescription') }}
        </p>

            <div class="horizontal-line"></div>

            <h4 class="total-payment-title">
                {{ $t('totalPayment') }}
            </h4>
        <h3 class="amount-of-payment">
            1000 TRY 
        </h3>





        <button class="go-to-create-ad" @click="goToCreateAd">{{ $t('goToCreateAdSection') }}</button>

        </div>
    
    </div>
    
</template>

<script>

import UserCommercialNavbar from '@/components/commercial-user-section/NavbarCommercialUser.vue' // Navbar
import { mapGetters } from 'vuex';
export default {

    components: {
        UserCommercialNavbar
    },
    data(){
        return {
            paymentDetails: null,
        }
    },

    unmounted () {
        this.$store.commit('setPaymentSessionId', null)
    },
    computed:{
        ...mapGetters(['getPaymentSessionId']),
    },
    async created() {
        
        if(this.getPaymentSessionId){
            await this.verifyPayment(this.getPaymentSessionId)
        }
    },

    mounted() {
        if(!this.getPaymentSessionId){
            alert('Session Id not found. Please take a new payment.')
            this.$store.commit('setSelectedCommercialSection','LoadBalance' )
            this.$router.push({ name: 'user-commercial' })
        }
    },
    methods: {
        goToCreateAd() {
            this.$store.commit('setSelectedCommercialSection','PublishAds' )

            this.$router.push({ name: 'user-commercial'})
        },
        async verifyPayment(sessionId) {
            try {
                const result = await this.$store.dispatch('verifyPayment', 
                    sessionId
                )

                if(result?.status_code == 200){
                    this.paymentDetails = result?.data
                }
            } catch (error) {
                console.log(error)
                alert('Something went wrong', error)
                this.$store.commit('setPaymentSessionId', null)
                this.$store.commit('setSelectedCommercialSection','LoadBalance' )
                this.$router.push({ name: 'user-commercial' })
            }
            
        }

    }
}
</script>

<style scoped lang="scss">

.success-payment-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2rem;
    background: #e7fbea;


    & .success-payment-content {
        display: flex;
        width: 34rem;
        margin: 0 auto;
        height: max-content;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: flex-start;
        gap: .3rem;
        border-radius: 12px;
        padding: 1rem;
        box-sizing: border-box;
        background-color: #ffffff;
        border: 2px solid #eefded;

        & .success-icon {
            width: 4rem;
            height: 4rem;
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: center;
            gap: .5rem;
            padding: .5rem;
            box-sizing: border-box;
            border-radius: 50%;
            background-color: #E5F4EC;

            & img {
                width: 2rem;
                height: 2rem;
                object-fit: scale-down;
            }
        }

        & .success-payment-title {
            font-size: 1.1rem;
                font-weight: 750;
                color: #1E1E1E;
                margin: 0;
                padding: 0;
                margin-top: .5rem;
            }

            & .success-payment-description {
                font-size: .8rem;
        font-weight: 500;
        color: #545454;
        margin: 0 auto;
        padding: 0;
            }

            & .horizontal-line {
                width: 80%;
                height: 1px;
                background-color: #D9D9D9;
                margin: .5rem auto 1rem auto;
                padding: 0;
            }

            & .total-payment-title {
                font-size: .8rem;
        font-weight: 500;
        color: #545454;
        margin: 0 auto;
        padding: 0;
            }


            & .amount-of-payment {
                font-size: 1.3rem;
                font-weight: 750;
                color: #1E1E1E;
                margin: 0;
                padding: 0;
            }

    }


    & .success-payment-description {
        font-size: .7rem;
        font-weight: 500;
        color: #393939;
        margin: 0;
        padding: 0;
    }

    & .go-to-create-ad {
        width:max-content;
        height: max-content;
        padding: .8rem 1.6rem;
        border-radius: 4px;
        border: 0;
        background-color: #24b2ff;
        color: #ffffff;
        font-size: .8rem;
        font-weight: 600;
        margin: 1rem auto ;
        cursor: pointer;

        &:hover {
            opacity: .8;
        }
    }
}
</style>