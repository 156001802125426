import store from '../store';
class SSEManager {
  constructor(baseURL) {
    this.baseURL = baseURL;
    this.eventSource = null;
    this.subscribedChannels = new Set();
    this.userId = null;
    this.sessionId = null;
    this.maxRetries = 1;
    this.channelArray = [];
  }

  async connect(userId, channelArray = []) {

    this.userId = userId;
    this.channelArray = channelArray;
    this.sessionId = store.getters['getSessionId'] || ''; // Her oturum için benzersiz bir ID oluştur
    this.subscribedChannels = new Set(channelArray);

    const existedEventSource = store.getters['getEventSource'];
    if (existedEventSource) {
      this.eventSource = existedEventSource?.eventSource;
      this.subscribedChannels = existedEventSource?.subscribedChannels;
      this.userId = existedEventSource?.userId;
      this.sessionId = existedEventSource?.sessionId;
      this.channelArray = existedEventSource?.channelArray;
      this.maxRetries = 1;
    }
    else {
      store.commit('setEventSource', {
        eventSource: this.eventSource,
        subscribedChannels: this.subscribedChannels,
        userId: this.userId,
        sessionId: this.sessionId,
        channelArray: this.channelArray
      });
    }

    const channels = channelArray.join(',');


    this.eventSource = new EventSource(
      `${this.baseURL}/api/v0.0.1/sse/subscribe/${userId}/${this.sessionId}/${channels}`
    );
    console.log('SSE bağlanıyor...', `${this.baseURL}/api/v0.0.1/sse/subscribe/${userId}/${this.sessionId}/${channels}`);

    this.eventSource.onopen = () => {
      console.log('SSE bağlantısı bağlandı.');
    };

    this.eventSource.onmessage = (event) => {
        console.log('message')
      const data = JSON.parse(event.data);
      this.handleEvent(data.type, data.payload);
    };

    this.eventSource.onerror = (error) => {
      console.error('SSE Hatası:', error);
      if(this.maxRetries > 0){
        this.reconnect();
      }
      this.maxRetries--;


    };
  }

  reconnect() {
    if (this.eventSource && this.eventSource.readyState === EventSource.CLOSED && this.maxRetries > 0) {
      this.connect( this.userId, this.channelArray);
      
    //   this.connect(this.userId, Array.from(this.subscribedChannels));

    }

  
  }

  handleEvent(type, payload) {
    const userId = this.userId;
    const preName = 'user'
    const luckmeAction = preName+':'+userId+':'+'luckmecount';
    const logout = preName+':'+userId+':'+'logout';
    const adConsumed = 'ad:consumed';
    if(type == luckmeAction){
        if(typeof payload == 'object' && payload){
            store.commit('individualUserModule/setLuckmeAction', payload);
        }
    }
    else if(type == adConsumed){
        if(typeof payload == 'object' && payload){
            store.commit('individualUserModule/setAdConsumed', payload);
        }
    }
    else if(type == logout){
        this.disconnect();
       store.dispatch('logout');
    }

    console.log(type, payload);

  }

  disconnect() {
    if (this.eventSource) {
      this.eventSource.close();
      this.eventSource = null;
      this.subscribedChannels = new Set();
      this.userId = null;
      this.sessionId = null;
      this.maxRetries = 1;
      store.commit('setEventSource', null);
    }
  }
}

export default {
  install(Vue, options) {
    const { baseURL } = options;
    Vue.config.globalProperties.$sse = new SSEManager(baseURL);
  },
};
