import baseServices from './index';

export default {
    getInitialAds: async (userInfo,filter,lang,userWatchedAds) => {

        try {   

            const result = await baseServices().post('individual-user/get-initial-ads', {
                userInfo,
                filter,
                lang,
                userWatchedAds
            }
             );
            return result;

        } catch (error) {
            console.log(error);
            return error;
        }
    },

    getFilterCategories: async (filter) => {
        try {
            const result = await baseServices().post('individual-user/get-filter-categories', 
                filter
            );
            return result;
        } catch (error) {
            console.log(error);
            return error;
        }
    },
    getUpdateBalance: async (updateBalanceInfo) => {
        try {
            const result = await baseServices().post('individual-user/get-updated-balance', updateBalanceInfo);
            return result;
        } catch (error) {
            console.log(error);
            return error;
        }
    },

    getPendingAds: async (userInfo,filter,activeAdIds,lang,userWatchedAds) => {
        try {
            const result = await baseServices().post(`individual-user/get-pending-ads`,{
                userInfo,
                filter,
                activeAdIds,
                lang,
                userWatchedAds
            });
            return result;
        } catch (error) {
            console.log(error);
            return error;
        }
    },

    fetchRemaningSecondWatchAdsRightReset : async (userId) => {
        try {
            const result = await baseServices().get(`individual-user/fetch-remaining-second-watch-ads-right-reset/${userId}`);
            return result;
        } catch (error) {
            console.log(error);
            return error;
        }
    },

    setAdWatched: async ({adId,userId,adWatchedMetaData,sessionId}) => {
        try {
            const result = await baseServices().post(`individual-user/set-ad-watched`,{
                adId,
                userId,
                adWatchedMetaData,
            });
            return result;
        } catch (error) {
            console.log(error);
            return error;
        }
    },
    setLuckmeGameAttempResult: async (payload) => {
        try {
            const result = await baseServices().post(`individual-user/get-luckme-game-result`,
                payload
            );
            return result;
        } catch (error) {
            console.log(error);
            return error;
        }
    }
};