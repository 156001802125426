<template>
  <div class="default-bank-card-container">
    <h2 class="payment-section-title"><span>{{ $t('paymentSectionTitle') }}</span>
    <img src="/img/app_logos/dark_app_logo.png" alt="app logo" class="app-logo-payment">
    </h2>

    <div class="set-amount-of-payment-container">
      <input type="number" :min="minAmount" class="amount-input" :value="amount" @input="setAmount($event)" :placeholder="getPaymentPlaceHolder" />
      <div class="user-currency-name">
        {{ getSelectedCurrency }}
      </div>

    </div>

      <button @click="addBalance" class="add-balance-btn" :class="{'disabled': !getIsFormValid || isPaymentRequestDisabled}">{{ $t('makePayment') }}</button>

    </div>


</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { throttle,debounce } from 'lodash';

export default {
  props: {
    currency: {
      type: String,
      required: true
    },
    userInfo: {
      type: Object,
      required: true
    },
    selectedLanguage: {
      type: String,
      required: true
    }
  },
  data() {
    return {

      amount: '',
      country:'',
      userId:'',
      customerEmail: '',
      countryCode: '',
      minAmount:10,
      message: '',
      productName: '',
      productDescription: '',
      brandImage: 'https://storage.googleapis.com/felixleap/app_assets/images/dark_app_logo.png',
      isPaymentRequestDisabled: false,


    };
  },

  async mounted() {
        this.productName  = 'FELIXLEAP';
        this.productDescription = this.$t('balanceLoadDescription');
    },

  created() {
    this.selectedCountry = this.userInfo.country['alpha-2'] || '';
    this.country = this.userInfo.country.names[this.selectedLanguage] || '';
    this.countryCode = this.userInfo.country['alpha-2'] || '';
    this.customerEmail = this.userInfo.email;
    this.userId = this.userInfo.userId;


  },


  computed: {
 
    getPaymentPlaceHolder() {
      return this.$t('paymentIntentPlaceHolder');
    },
    getSelectedCurrency() {
      return this.currency.toUpperCase();
    },
    getIsFormValid(){
      return this.amount >= this.minAmount;
    },


  },
  methods: {

    addBalance() {  

      if (!this.getIsFormValid) {
        alert(this.$t('error.completeForm'));
        return;
      }
      this.initializePaymentRequest();
    },

    goPaymentPageUrl(url) {

      window.location.href = url;
    },
    setSessionId(sessionId) {
      this.$store.commit('setPaymentSessionId', sessionId);
    },

    
     initializePaymentRequest : throttle(async function(){
            if (this.amount < this.minAmount) {
                this.message = this.$t('invalidAmountMessage');
                return;
            }

            // Ödeme talebini başlatmadan önce butonu devre dışı bırak
            this.isPaymentRequestDisabled = true;

            try {
                // Payment Intent oluşturma
                const response = await this.$store.dispatch('commercialUserModule/getPaymentIntent', {
                    amount: this.amount * 100,
                    currency: this.currency,
                    country: this.country,
                    customerEmail: this.customerEmail,
                    userId: this.userId,
                    productData:{
                        name: this.productName,
                        description: this.productDescription,
                        images: [this.brandImage],
                    },
                    
                });


                if (response.status_code === 200) {
                this.setSessionId(response.sessionId);
                 this.goPaymentPageUrl(response.url)

                    // this.checkOtherPaymentMethods();
                } else {
                    this.message = 'Ödeme isteği oluşturulamadı. Lütfen tekrar deneyin.';
                }
            } catch (error) {
                this.message = `Hata: ${error.message}`; // Hata mesajını göster
            } finally {
                this.isPaymentRequestDisabled = false; // Butonu tekrar etkinleştir
            }
        }
        , 1000)
        ,

    setAmount: debounce(function(event) {

      if(event.target.value >= this.minAmount){

        this.amount = event.target.value;
      }
      else {

        this.amount = '';
      }

    }, 500),

  }
};
</script>

<style scoped lang="scss">

.disabled {
  pointer-events: none;
  cursor: not-allowed !important;
  opacity: .5;
}
.default-bank-card-container {
  width: 100%;
  height: max-content;
  background: #ffffff;
  padding: 1rem 2rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 auto 0 auto;
  gap: .5rem;
border: 1px solid #ddd;

  box-sizing: border-box !important;

  .error-message {
    color: red;
    font-size: .7rem;
    font-weight: 500;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: -1.5rem;
    right: 0;
  }

  h2,
  p {
    margin: 0;
    padding: 0;
  }

  .payment-section-title {
    font-size: .75rem;
    font-weight: 550;
    color: #393939;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    .app-logo-payment {
      width: 2.6rem;
      height:2.6rem;
      object-fit:scale-down;
    }
  }

  .set-amount-of-payment-container {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    position: relative;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: .1rem;



    .amount-input {
      width: 90%;
      height: 100%;
      padding: .5rem;
      border: 0;
      font-size: .7rem;
      font-weight: 500;
      color: #232323;
      outline: none;
      transition: border-color 0.2s;

      &::placeholder {
        color: #6a6a6a;
        font-size: .7rem;


      }

    }

    .user-currency-name {
      width: 10%;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;
      font-weight: 550;
      font-size: .7rem;
      color: rgb(26, 26, 26);
    }



  }



  .add-balance-btn {
    width: 100%;
    height: 2.5rem;
    font-size: .7rem;
    font-weight: 600;
    margin-top: .5rem;
    color: #ffffff;
    outline: none;
    border: 0;
    border-radius: 4px;
    transition: border-color 0.2s;
    cursor: pointer;
    background-color: #0BA374;

    &:hover {
      opacity: .8;
    }
  }



}</style>