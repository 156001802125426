<template>
  <div class="luckme-game-container"  ref="gameContainer">
    <h3>{{ $t('luckyMeGame') }}</h3>
    <p class="luckme-game-intro"> <span>{{ $t('luckyMeGameIntro') }}</span> <span class="earn-emoji" v-html="'\uD83D\uDCB0'"></span></p>

    <div class="luckme-game-playground" :key="isLuckmeAttemptGameResultFetching ? '1':'0' + getLuckmeGameAttemptNumbersResult.length">
      <div class="luckme-game-card"
        :style="{ background: luckyNumber === 0 ?`url('/app_icons/button-luckme-number.png')`: luckyNumber && getLuckmeGameNumberIsWinned(luckyNumber) ?  `url('/app_icons/luckme-winning-2.png')`:`url('/app_icons/luckme-regular-result.png')`, backgroundSize: '100% 100%' }" v-for="(luckyNumber, index) in this.getLuckmeGameAttemptNumbersResult"
        :key="(luckyNumber ? luckyNumber : 'none')+index">
        <p class="luckme-game-card-result-number" :style="{color:getLuckmeGameNumberIsWinned(luckyNumber) ? '#FFFFFF':'#26202b'}" v-if="luckyNumber !== 0">{{ luckyNumber + ' ' + getLuckmeGameDetail?.luckmeGamePlayGround?.currency_sign }}</p>
        <img v-else-if="isLuckmeAttemptGameResultFetching" class="luckme-game-card-icon" style="scale:1.5" src="/app_icons/shamrock-clover.gif" alt="shamrock_happy">
        <img v-else class="luckme-game-card-icon" src="/app_icons/shamrock.png" alt="shamrock">



      </div>
    </div>
    <button class="luckme-game-button" @click="setLuckmeGameAttempResult" :style="{
      background: `url('/app_icons/luckme-btn.png')`,
      backgroundSize: '100% 100%'
    }">
      <span>{{ $t('luckyMe') }}</span> 
    </button>

    <div class="luckme-game-details">
      <div class="luckme-game-details-first-section">
      <div class="luckme-game-luck-numbers">
        <label class="luckme-game-luck-numbers-title">
          {{ $t('luckyNumbers') }}
        </label>
     
          <p class="luckme-game-luck-numbers-value" v-html="getLuckyNumbers"> </p>
       
      </div>

      <div class="remaining-attempts-to-luckme">
        <div class="remaining-attempts-to-luckme-value-container">
          <p 
        :style="{
      background: `url('/app_icons/luckme-count-2.png')`,
      backgroundSize: '100% 100%'
    }"
     class="remaining-attempts-to-luckme-value">{{ getLuckmeGameDetail?.remainingAttempts }}</p>
            </div>


        <label class="remaining-attempts-to-luckme-title">
          {{ $t('remainingLuckmeAttempts') }}
        </label>
      </div>
    </div>

      <div class="luckme-game-details-second-section">
        <div class="luckme-game-total-earning">
          <label class="luckme-game-total-earning-title">
            {{ $t('totalEarning') }}
          </label>
          <p class="luckme-game-total-earning-value">{{ getTotalEarning }}</p>
        </div>
      </div>

    </div>
<audio style="display: none" src="/app_sounds/cash-earned.mp3" ref="cashSound"></audio>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {throttle} from 'lodash'
import moment from 'moment';
export default {
  data(){
    return {
      popupErrorMessage: '',
      isLuckmeAttemptGameResultFetching: false,
      currentLuckmeGameNumberResult:[
        0,
        0,
        0,
        0,
        0,
        0
      ],
      luckmeAttemptEndedByWin : false,
      winRepeatCount : 3
    }
  },
  mounted() {
    // Tüm dokümana bir click listener ekleyin
    document.addEventListener('click', this.handleClickOutside);
  },

  beforeUnmount() {

    // Component yok edilmeden önce listener'ı kaldırın
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods : {

    handleClickOutside(event) {
      if (!((this.$refs?.gameContainer || []).contains(event?.target)) && !this.isLuckmeAttemptGameResultFetching) {
        this.resetLuckmeGame();
      }
    },
    resetLuckmeGame(){
      this.currentLuckmeGameNumberResult = [];
      this.currentLuckmeGameNumberResult = [
        0,
        0,
        0,
        0,
        0,
        0
      ];
      this.luckmeAttemptEndedByWin = false;
      this.isLuckmeAttemptGameResultFetching = false;
      this.popupErrorMessage = '';
 


    },

    setEarnedReaction(gameResult){
      if(!gameResult){
        return
      }
      const randomIndexValues = [1,2]
      const randomIndex = Math.floor(Math.random() * randomIndexValues.length);

      const latestResult = {
        isWinned:gameResult?.isWinned || false,
        earnedAmount: gameResult?.earnedNumber || 0,
        currency: gameResult?.currency || 'none',
        isLoss:randomIndexValues[randomIndex || 0] || 1,
        updatedDate:moment().format('DD/MM/YYYY HH:mm')
      }
      this.$store.commit('individualUserModule/setLuckmeLatestResult', latestResult);
      this.triggerVibration();
      this.playWinningSound();
      
    },
    playWinningSound() {
      // const audio = new Audio('path/to/success-sound.mp3'); // Ses dosyanızın yolunu buraya girin
      // audio.play();
      this.$refs.cashSound.play();

    },
    triggerVibration() {
      if ("vibrate" in navigator) {
        // 500 ms titreşim
        navigator.vibrate(1000);
      } else {
        console.log("Titreşim özelliği bu cihazda desteklenmiyor.");
      }
    },
    setPopupErrorMessage(message){
      this.popupErrorMessage = message
      setTimeout(() => {
        this.removeErrorMessage();
      }, 5000);
    },
    removeErrorMessage(){
      this.errorMessage = ''
    },
    setUpdatedGameNumberResult(luckyNumbers){
      this.currentLuckmeGameNumberResult = luckyNumbers;
    },
    updateLuckmeLatestDetail(isLoss){
      const latestDetailLuckmeGame = this.getLuckmeLatestResult;
      latestDetailLuckmeGame.isWinned =  false
      latestDetailLuckmeGame.earnedAmount = 0
      latestDetailLuckmeGame.updatedDate = moment().format('DD/MM/YYYY HH:mm');

        if(isLoss <= 0){
          latestDetailLuckmeGame.isLoss = 0;
        }
        else {
          latestDetailLuckmeGame.isLoss = isLoss;
        }

        this.$store.commit('individualUserModule/setLuckmeLatestResult', latestDetailLuckmeGame);
    },
    getLuckmeGameNumberIsWinned(luckyNumber){
        let numberRepeatCount = 0;
      this.currentLuckmeGameNumberResult.forEach(number => {
        if(number === luckyNumber){
          numberRepeatCount++
        }
      })

      return numberRepeatCount === this.winRepeatCount
    },

     setLuckmeGameAttempResult : throttle(async function(){
      try {

          if(!this.isLuckmeAttemptGameActive){
            this.setPopupErrorMessage(this.$t('noAttemptsLeft'))
            return ;
          }
          if(this.isLuckmeAttemptGameResultFetching){
            return;
          }
          const userId = this.getUserIndividualInfo?.userId || '';
          if(!userId){
            this.setPopupErrorMessage(this.$t('noUserId'))
            return ;
          }
          this.resetLuckmeGame();

          this.isLuckmeAttemptGameResultFetching = true;
          const response = await this.$store.dispatch('individualUserModule/setLuckmeGameAttempResult',{userId,latestLuckmeResult: this.getLuckmeLatestResult});
          if(!response?.status === 'success' && response?.status_code !== 200){
            this.setPopupErrorMessage(this.$t('luckmeGameError'))
            return
          }

          this.setUpdatedGameNumberResult(response?.data?.game?.luckmeGameResultNumbers || []);
          this.luckmeAttemptEndedByWin = response?.data?.game?.isWinned;
          if(this.luckmeAttemptEndedByWin){
            this.setEarnedReaction(response?.data?.game || null);
          }
          else {
            this.updateLuckmeLatestDetail(response?.data?.game?.isLoss || 0);
          }
         




      }
      catch (error) {
        setTimeout(() => {
          this.resetLuckmeGame();
        }, 3000);
        this.setPopupErrorMessage(this.$t('luckmeGameError'))

        console.log(error)
      }
      finally {
          this.isLuckmeAttemptGameResultFetching = false

      }
    },3000),
  },

  computed:{
    ...mapGetters(['getSelectedLanguage']),
    ...mapGetters({getUserIndividualInfo:'individualUserModule/getUserIndividualInfo',getLuckmeLatestResult:'individualUserModule/getLatestLuckmeResult'}),

    getLuckmeGameDetail(){
        return this.getUserIndividualInfo?.luckmeGameDetails || {}
    },
    getLuckmeAttemptFinalResult(){
      return this.luckmeAttemptEndedByWin
    },
    getLuckmeGameAttemptNumbersResult(){
      return this.currentLuckmeGameNumberResult
    },
    isLuckmeAttemptGameActive(){
      return this.getLuckmeGameDetail?.remainingAttempts 
    },
    getLuckyNumbers(){
      const numbers = this.getLuckmeGameDetail?.luckmeGamePlayGround?.numbers || [];
       const numberValues = numbers.map(number => {
          return number?.value
        })
        if(!numberValues.length) return ''
    
        return numberValues.map((x,index)=>{
          return `<span style="font-weight:${index === (numberValues.length - 1) ? '900' : '550'} !important; font-size:${index === (numberValues.length - 1) ? .8 : .6}rem; color:${ index === (numberValues.length - 1) ? '#6082f1' : '#86A7FC'}">${x} <span> ${ (index !== (numberValues.length - 1) ? ',' : '')} </span></span>`
        }).join('');
    },
    getTotalEarning(){
      return (this.getLuckmeGameDetail?.totalWinAmount ?? 0).toFixed(0) + ' ' + this.getLuckmeGameDetail?.luckmeGamePlayGround?.currency_sign
    }

  },
}
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Cantata+One&family=DynaPuff:wght@400..700&family=Metal+Mania&family=New+Rocker&family=Playpen+Sans:wght@100..800&family=Rubik+Distressed&display=swap');

.luckme-game-container {
  width: 100%;
  height: 34rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1.5rem;
  padding-bottom: 0;
  background: linear-gradient(to bottom, #d1dfff 0%, #e9f0fe 100%);

  border-radius: 6px;
  border: 1px solid #ecf2ff;
  box-sizing: border-box;

  @media screen and (max-width: 768px) {
    height: max-content;
    padding: 2rem 22%;
    margin: 1rem auto;
  }

  @media screen and (max-width: 700px) {
    height: max-content;
    padding: 2rem 20%;
  }

  @media screen and (max-width: 650px) {
    padding: 2rem   18%;
  }

  @media screen and (max-width: 550px) {
    padding: 2rem 16%;
  }

  @media screen and (max-width: 450px) {
    padding: 2rem 14%;
  }

  & p,
  & h3 {
    margin: 0;
    padding: 0;

  }

  & h3 {
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
    font-weight: 1000;
    color: #6082f1;
    text-align: center;
    width: 100%;
    font-family: "DynaPuff", serif;

  }

  & .luckme-game-intro {
    font-size: .7rem;
    font-weight: 650;
    color: #6082f1d4;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: .2rem;

    & .earn-emoji {
      font-size: 1.3rem;
    }

  }

  & .luckme-game-button {
    width: 12rem;
    height: 3rem;
    min-height: 3rem;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    border:0;
    font-weight: 900;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    font-size: 1rem;
    color: #ffea00;
    text-shadow: 0px 1px 1px #000000;
    // filter: drop-shadow(0px 1px 1px #cfcfcf);
    margin: 1.4rem auto;
    font-family: "DynaPuff", serif;
    letter-spacing: .1rem;
    transition: all .5s ease-in-out;
    // box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &:hover {
      filter: drop-shadow(1px 2px 4px  #829df5);

    }

    @media screen and ((max-width: 768px) and (min-width: 360px)) {
        height: 3.2rem;
        min-height: 3.2rem;
    }


    & img {
      width: 1.5rem;
      height: 1.5rem;
      object-fit: scale-down;
      margin-left: .5rem;
    }

  }

  & .luckme-game-details {
    width: 96%;
    margin: 0 auto;
    height: 30%;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
    background: #ffffff;
    padding: 0 .5rem ;
    box-sizing: border-box;
    border-radius: 8px;

    & .luckme-game-details-first-section {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      width: 56%;
      height: 100%;
      gap: 1rem;
      padding: 1rem 0;
      box-sizing: border-box;

      border-right: 1px #d9e1ff solid;
     
      & .luckme-game-luck-numbers {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: flex-start;
        gap: .2rem;
        width: 100%;

        & label {
          font-size: .6rem;
          font-weight: 750;
          color: #3468C0;

          text-transform: capitalize;
        }

        & .luckme-game-luck-numbers-value {
          font-size: .6rem;
          font-weight: 550;
          color: #86A7FC;


          text-transform: capitalize;

        }

      }

      & .remaining-attempts-to-luckme {
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: flex-start;
        gap: .5rem;



        & .remaining-attempts-to-luckme-title {
          font-size: .65rem;
          font-weight: 800;
          color: #3468C0;
          text-transform: capitalize;

        }

          & .remaining-attempts-to-luckme-value-container {
            width: max-content;
            height: max-content;
            border: 3px dashed #4a3a61;
            border-radius: 50%;

          }
        & .remaining-attempts-to-luckme-value {
          font-size: 1rem;
          font-weight: 1000;
          color: #26202b;
          text-transform: capitalize;
          width: 2.5rem;
          height: 2.5rem;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          border:0;
          box-sizing: content-box;

        }

      }
    }

    & .luckme-game-details-second-section {

      display: flex;
      flex-flow: column nowrap;
      align-items: flex-end;
      justify-content: center;
      gap: 1rem;
      width: 40%;
      height: 100%;
      margin: auto;

      & .luckme-game-total-earning {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: flex-start;
        gap: .1rem;
        width: 100%;


        & label {
          font-size: .7rem;
          font-weight: 600;
          color: #3468C0;
          text-transform: capitalize;
          text-align: center;
        }

        & .luckme-game-total-earning-value {
          font-size: 1.6rem;
          font-weight: 800;
          color: #17dd45;
          text-transform: capitalize;
        }
      }



    }
  }

  & .luckme-game-playground {
    width: 100%;
    height: max-content;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 1rem;

    & .luckme-game-card {
      width: 48%;
      height: 3rem;
      min-height: 3rem;
      max-height: 3rem;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;
      border-radius: 0;
      border: 0;
      margin-top: .5rem;
      color: #605678;
      font-weight: 800;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      font-size: .7rem;
      box-sizing: border-box;
      filter: drop-shadow(0px 1px 1px #cfcfcf);

      @media screen and ((max-width: 768px) and (min-width: 360px)) {
        height: 4.5rem;
        min-height: 4.5rem;
        font-size: .8rem;
    }


   

      & .luckme-game-card-result-number {
        font-size: 1rem;
        font-weight: 900;
        color: #26202b;
      }
      & .luckme-game-card-icon {
        width: 1.2rem;
        height: 1.2rem;
        object-fit: scale-down;
      }

      @media screen and (max-width: 768px) {
        height: 4rem;

      }


    }
  }


}</style>